.header {
  position: fixed;
  top: 0;
  left: 400px;
  z-index: 49;

  width: calc(100% - 400px);
  height: 90px;

  background-color: rgba(255, 255, 255, 0.94);
  border-bottom: 1px solid #d1d1d1;

  @include m-1368 {
    left: 65px;

    width: calc(100% - 65px);
  }

  @include m-768 {
    left: 0;

    height: 60px;
    width: 100%;
  }

  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4%;
    padding: 20px 50px;

    @include m-992 {
      padding: 20px;
    }

    @include m-768 {
      padding: 15px 20px;
    }
  }

  .burger {
    position: relative;

    display: none;
    width: 25px;
    height: 25px;

    @include m-768 {
      display: block;
    }

    span {
      position: absolute;
      top: 50%;
      left: 0;

      width: 25px;
      height: 3px;

      background-color: #6b6662;

      transform: translateY(-50%);

      &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 0;

        width: 25px;
        height: 3px;

        background-color: #6b6662;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 10px;
        left: 0;

        width: 25px;
        height: 3px;

        background-color: #6b6662;
      }
    }
  }

  .logo {
    display: none;
    height: 30px;

    overflow: hidden;

    @include m-768 {
      display: block;
    }

    &.logo--hide {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
    }
  }

  .search-form {
    height: 40px;
    display: flex;
    flex: 1;

    @include m-768 {
      flex: none;
      margin-left: auto;
      height: 30px;
    }

    &.search-form--open {
      flex: 1;
      margin-left: 0;

      .search-form__input {
        display: block;
      }

      .search-form__btn {
        display: block;
      }

      .search-form__open-btn {
        display: none;
      }
    }

    .search-form__input {
      height: 40px;
      padding: 0 25px;
      width: 100%;

      border: 1px solid #2995c9;
      border-radius: 34px 0 0 34px;
      background-color: #ffffff;

      @include m-768 {
        display: none;
        height: 30px;
      }
    }

    .search-form__btn {
      position: relative;

      width: 50px;
      height: 40px;

      border-radius: 0 34px 34px 0;
      background-color: #2995c9;

      @include m-768 {
        display: none;
        width: 40px;
        height: 30px;
      }

      &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;

        width: 22px;
        height: 22px;

        background-repeat: no-repeat;
        background-image: url("../img/svg/search.svg");
        background-position: center;
        background-size: contain;

        transform: translate(50%, 50%);

        @include m-768 {
          top: 0;
          left: 0;

          width: 15px;
          height: 15px;
        }
      }
    }

    .search-form__open-btn {
      width: 25px;
      height: 30px;
      display: none;

      background-repeat: no-repeat;
      background-image: url("../img/svg/search-blue.svg");
      background-size: contain;
      background-position: center;

      @include m-768 {
        display: block;
      }
    }
  }

  .call-btn {
    height: 40px;
    padding: 0 25px;

    color: #2995c9;

    background-color: #ffffff;
    border: 1px solid #2995c9;
    border-radius: 34px;

    transition: all .4s ease;

    @include m-768 {
      display: none;
    }

    &:hover {
      color: #ffffff;

      background-color: #2995c9;
    }
  }

  .contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include m-992 {
      display: none;
    }

    @include m-768 {
      display: block;
    }

    .contacts__tel-link {
      display: block;

      font-size: 20px;
      text-decoration: none;
      color: #2995c9;

      @include m-768 {
        width: 25px;
        height: 25px;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../img/svg/phone.svg");

        transform: rotateY(180deg);
      }

      p {

        @include m-768 {
          display: none;
        }
      }
    }

    .contacts__mail-link {
      color: #6b6662;
      font-size: 20px;
      text-decoration: none;

      transition: all .4s ease;

      @include m-768 {
        display: none;
      }
    }
  }

  .cart {
    display: grid;
    grid-template-columns: 50px auto;
    grid-template-rows: auto auto;
    align-items: center;
    grid-gap: 0 5px;

    text-decoration: none;

    @include m-768 {
      grid-gap: 0;
      grid-template-columns: auto;
      grid-template-rows: auto;
    }

    .img-wrapper {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
      width: 50px;
      height: 50px;

      overflow: hidden;

      @include m-768 {
        width: 25px;
        height: 25px;
      }

      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        transform: rotateY(180deg);
      }
    }

    .cart__count {
      font-weight: 500;
      color: #171717;

      @include m-768 {
        display: none;
      }
    }

    .cart__summ {
      font-weight: 500;
      color: #171717;

      @include m-768 {
        display: none;
      }

      .big {
        font-size: 20px;
      }
    }
  }

  .nav {
    padding: 10px 50px;

    background-color: #f0f0f0;
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;

    @include m-992 {
      padding: 10px 20px;
    }

    @include m-768 {
      display: none;
      padding: 20px;

      background-color: #ffffff;
      box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.47);
    }

    &.nav--open {
      display: block;
    }

    .nav-list {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 30px;

      @include m-768 {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }

      .nav-list__link {
        text-decoration: none;
        color: #6b6662;
        font-weight: 500;

        transition: all .4s ease;

        &:hover {
          color: #2995c9;
        }
      }
    }
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;

  padding-top: 80px;
  min-height: 100vh;
  width: 400px;

  background-color: #ffffff;
  box-shadow: 0 5px 24.57px 2.43px rgba(0, 0, 0, 0.47);

  transition: all .4s ease;

  @include m-1368 {
    width: 65px;
  }

  @include m-768 {
    position: static;

    width: 100%;
    height: auto;
    min-height: 0;
    padding-top: 60px;

    box-shadow: none;
    border-bottom: 1px solid #d1d1d1;
  }

  .logo {
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 200px;

    overflow: hidden;

    @include m-1368 {
      width: 45px;
      height: auto;
    }

    @include m-768 {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
    }
  }

  .sidebar-list-btn {
    display: none;
    width: 100%;
    height: 40px;

    text-transform: uppercase;
    font-size: 14px;

    border-bottom: none;

    &.sidebar-list-btn--open {
      border-bottom: 1px solid #d1d1d1;

      .open {
        display: none;
      }

      .close {
        display: block;
      }
    }

    @include m-768 {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .open {
      display: block;
    }

    .close {
      display: none;
    }
  }

  .sidebar-list {
    display: none;
    flex-direction: column;
    padding-top: 40px;
    margin-top: 40px;

    border-top: 1px solid #d1d1d1;

    @include m-768 {
      margin-top: 0;
      padding-top: 0;

      border: none;
    }

    &.sidebar-list--open {
      display: flex;
    }

    .sidebar-list__item {
      position: relative;

      overflow: hidden;
      cursor: pointer;

      &:hover {

        &.sidebar-list__item--1 {

          &::before {
            background-position: -48px 0;
          }
        }

        &.sidebar-list__item--2 {

          &::before {
            background-position: 32px 0;
          }
        }

        &.sidebar-list__item--3 {

          &::before {
            background-position: -47px 0;
          }
        }

        &.sidebar-list__item--4 {

          &::before {
            background-position: -46px 0;
          }
        }

        .sidebar-list__link {
          color: #2995c9;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 45px;

        width: 42px;
        height: 46px;

        transform: translateY(-50%);

        @include m-1368 {
          left: 9px;
        }

        @include m-768 {
          left: 20px;
        }
      }

      &.sidebar-list__item--1 {

        &::before {
          background-position: -5px 0;
          background-image: url("../img/left-menu_1.png");
        }
      }

      &.sidebar-list__item--2 {

        &::before {
          left: 52px;

          width: 30px;

          background-position: -2px 0;
          background-image: url("../img/left-menu_2.png");

          @include m-1368 {
            left: 16px;
          }

          @include m-768 {
            left: 26px;
          }
        }
      }

      &.sidebar-list__item--3 {

        &::before {
          background-position: -2px 0;
          background-image: url("../img/left-menu_3.png");
        }
      }

      &.sidebar-list__item--4 {

        &::before {
          background-position: 0 0;
          background-image: url("../img/left-menu_7.png");
        }
      }
    }

    .sidebar-list__link {
      display: block;
      padding: 20px 0 20px 110px;

      line-height: 1.2;
      color: #171717;

      text-decoration: none;

      @include m-1368 {
        width: 65px;
        height: 65px;

        overflow: hidden;
      }

      @include m-768 {
        width: 100%;
        padding: 20px 0 20px 80px;
      }
    }
  }
}

.nav {
  padding: 10px 50px;
  margin-left: -50px;
  width: calc(100% + 100px);

  background-color: #f0f0f0;
  border-bottom: 1px solid #d1d1d1;

  @include m-992 {
    padding: 10px 20px;
    margin-left: -20px;
    width: calc(100% + 40px);
  }

  @include m-768 {
    position: fixed;
    top: 60px;
    left: 0;

    width: 100%;
    margin: 0;
    display: none;
    padding: 20px;

    border-top: none;
    background-color: #ffffff;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.47);
  }

  &.nav--open {
    display: block;
  }

  .nav-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;

    @include m-768 {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .nav-list__link {
      text-decoration: none;
      color: #6b6662;
      font-weight: 500;

      transition: all .4s ease;

      &:hover {
        color: #2995c9;
      }
    }
  }
}

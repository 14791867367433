@mixin m-1368 {
  @media (max-width: 1368px) { @content; }
}

@mixin m-992 {
  @media (max-width: 992px) { @content; }
}

@mixin m-768 {
  @media (max-width: 768px) { @content; }
}

* {
  box-sizing: border-box;
  outline: none;
}

body {
  min-height: 100vh;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  background-color: white;

  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, ul, p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

.container {
  width: calc(100% - 400px);
  min-height: 100vh;
  margin-left: 400px;
  padding: 90px 50px 0;

  @include m-1368 {
    width: calc(100% - 65px);
    margin-left: 65px;
  }

  @include m-992 {
    padding: 90px 20px 0;
  }

  @include m-768 {
    width: 100%;
    margin-left: 0;
    padding: 0 20px;
  }
}

button,
input,
textarea {
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;
  font: inherit;
}

.visually-hidden:not(:focus):not(:active),
input[type=checkbox].visually-hidden,
input[type=checked].visually-hidden,
input[type=radio].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

button:hover,
button:active,
button:focus,
input:hover,
input:active,
input:focus {
  outline: none;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color:transparent;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color:transparent;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color:transparent;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  color:transparent;
}

.text {
  margin-top: 20px;
}
